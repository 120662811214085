<template>
  <div class="register-index">
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
export default {
}
</script>
<style lang="less">
.register-index {
}
</style>
